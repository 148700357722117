.row {
  display: flex;
}

.row-spacing-small > * {
  margin: 0px 5px;
}

.row-spacing-medium > * {
  margin: 0px 10px;
}

.row-spacing-large > * {
  margin: 0px 15px;
}

.row-padding-small {
  padding: 5px;
}

.row-padding-medium {
  padding: 10px;
}

.row-padding-large {
  padding: 15px;
}

.row-alignment-horizontal-left {
  justify-content: start;
}

.row-alignment-horizontal-center {
  justify-content: center;
}

.row-alignment-horizontal-right {
  justify-content: end;
}

.row-alignment-vertical-top {
  align-items: start;
}

.row-alignment-vertical-center {
  align-items: center;
}

.row-alignment-vertical-bottom {
  align-items: end;
}
