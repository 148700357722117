.app {
  font-family: "Linotte", "Montserrat";
}

h1,
h2,
h3 {
  user-select: none;
}

.highlight {
  width: fit-content;
  padding: 0 10px;
  background-image: linear-gradient(#feccce, #feccce);
  background-size: 100% 7px;
  background-repeat: no-repeat;
  background-position: 100% 0%;
  transition: background-size 0.7s, background-position 0.5s ease-in-out;
}

.highlight:hover {
  background-size: 100% 50%;
  background-position: 0% 100%;
  transition: background-position 0.7s, background-size 0.5s ease-in-out;
}

.shake-head:hover {
  animation: headShake;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.pulse {
  animation-name: pulse;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
