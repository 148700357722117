.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}
