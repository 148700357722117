body {
  margin: 0;
}

@font-face {
  font-family: "Linotte";
  src: local("Linotte"),
    url("../../public/fonts/Linotte/Linotte Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("../../public/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("../../public/fonts/Montserrat/Montserrat-SemiBold.ttf")
      format("truetype");
  font-style: normal;
  font-weight: 600;
}
