.column {
  display: flex;
  flex-direction: column;
}

.column-spacing-small > * {
  margin: 5px 0px;
}

.column-spacing-medium > * {
  margin: 10px 0px;
}

.column-spacing-large > * {
  margin: 15px 0px;
}

.column-padding-small {
  padding: 5px;
}

.column-padding-medium {
  padding: 10px;
}

.column-padding-large {
  padding: 15px;
}

.column-alignment-horizontal-left {
  align-items: start;
}

.column-alignment-horizontal-center {
  align-items: center;
}

.column-alignment-horizontal-right {
  align-items: end;
}

.column-alignment-vertical-top {
  justify-content: start;
}

.column-alignment-vertical-center {
  justify-content: center;
}

.column-alignment-vertical-bottom {
  justify-content: end;
}
